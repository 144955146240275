import { template as template_2e6ba6aee5474942b5940de6024841db } from "@ember/template-compiler";
const FKText = template_2e6ba6aee5474942b5940de6024841db(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
