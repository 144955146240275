import { template as template_4d7c70b86a9d4a7e8089311b3ed43da3 } from "@ember/template-compiler";
const FKLabel = template_4d7c70b86a9d4a7e8089311b3ed43da3(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
