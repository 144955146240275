import { template as template_3464abc6c6eb4f43a70b8ebfeb5dd2da } from "@ember/template-compiler";
const SidebarSectionMessage = template_3464abc6c6eb4f43a70b8ebfeb5dd2da(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
